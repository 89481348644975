import { logout as biomaAuthLogout } from '@quintoandar/bioma-auth/containers/actions';
import { COOKIE_DOMAIN } from 'config';
import { CRM_CONTEXT } from 'containers/Redirecting/constants';
import { useTrackEvent } from 'Base/Tracking/hooks/useTrackEvent';
import { clearListCookies } from 'utils/cookies';
import RiskAssessment from './riskAssessment';

const LOGOUT_PWA_EVENT = 'logout_pwa';
const QA_DOMAIN = '.quintoandar.com.br';

const trackLogout = (origin) => {
  const trackEvent = useTrackEvent();
  trackEvent({
    event: LOGOUT_PWA_EVENT,
    extraProperties: {
      origin,
    },
  });
};

export const LOGOUT_ORIGIN = {
  USER_LOGOUT: 'userLogout',
  LOGOUT_AND_REDIRECT: 'logoutAndRedirect',
  LOGOUT_AND_REDIRECT_TO_EXTERNAL_PATH: 'logoutAndRedirectToExternalPath',
  DONE_VISITS_PERMISSIONS_ERROR: 'doneVisitPermissionsError',
  UNAUTHORIZED_REDIRECTOR: 'unauthorizedRedirector',
};

export const logout = (origin) => {
  trackLogout(origin);
  localStorage.removeItem(CRM_CONTEXT);
  RiskAssessment.clearAccountId();
  return biomaAuthLogout(COOKIE_DOMAIN);
};

export const logoutClearListCookies = (origin) => {
  trackLogout(origin);
  clearListCookies(QA_DOMAIN);
};
