import HOUSE_TYPES from 'enums/houseTypes';
import CONDOMINIUM_TYPES from 'enums/condominiumTypes';

import appMessages from './messages';

export const FEATURE_TOGGLES = Object.freeze({
  AGENT_ACTIVITY: 'enable_agent_activity',
  SALE_OFFER_PROGRESS_TRACKING: 'enable_offers_page_v2',
  OFFERS_PAGE_V2: 'enable_offers_page_v2',
});

export const CONTRACT_STATUS = Object.freeze({
  DRAFT: 'Draft',
  SENT: 'Sent',
  SIGNING: 'Signing',
  SIGNED: 'Signed',
  ACTIVE: 'Active',
  TERMINATED: 'Terminated',
  CANCELLED: 'Cancelled',
});

export const DOCUMENTATION_STATUS = Object.freeze({
  APPROVED: 'Approved',
  REFUSED: 'Refused',
  NEW: 'New',
  IN_ANALYSIS: 'InAnalysis',
  DRAFT_CONTRACT: 'DraftContract',
});

export const OFFER_STATUS = Object.freeze({
  DRAFT: 'Draft',
  PROPOSED: 'Proposed',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  DISMISS: 'Dismiss',
});

export const OFFER_TURN = Object.freeze({
  OWNER: 'Owner',
  TENANT: 'Tenant',
});

export const OWNER_CONTRACT_STATUS = Object.freeze({
  WAITING: 'WaitingSign',
  SIGNED: 'Signed',
});

export const OWNER_DOC_STATUS = Object.freeze({
  WAITING: 'WaitingDocumentation',
  SENT: 'Sent',
  RESEND: 'Resend',
  APPROVED: 'Approved',
});

export const RENT_FLOW_STATUS = Object.freeze({
  START: 'Prospeccao',
  OLD_DRAFT: 'Draft',
  OFFER: ['Negociacao', 'Offer'],
  DOCUMENTATION: ['Documentacao', 'Documentation'],
  CONTRACT: ['Contrato', 'Contract'],
  CANCELLED: 'Cancelled',
  APPROVED: ['Fechado', 'Approved'],
});

export const REJECTION_REASONS = Object.freeze({
  TENANT: [
    'TenantDoesntAgreeWithValue',
    'TenantDoesntAgreeWithConditions',
    'TenantGaveUpApartment',
    'TenantRentedAnotherOutside',
    'TenantGaveUpRenting',
  ],
  OWNER: [
    'OwnerDoesntAgreeWithValue',
    'OwnerDoesntAgreeWithConditions',
    'OwnerRentedForAnotherTenantOutside',
    'OwnerGaveUpRenting',
    'OwnerSoldHouse',
  ],
  SYSTEM: [
    'TenantStalled',
    'OwnerStalled',
    'InactiveHouse',
    'UnpublishedHouse',
    'TenantRentedAnother5A',
    'OwnerRentedForAnotherTenant5A',
  ],
});

export const TENANT_CONTRACT_STATUS = Object.freeze({
  WAITING: 'WaitingSign',
  SIGNED: 'Signed',
});

export const TENANT_DOC_STATUS = Object.freeze({
  WAITING: 'WaitingDocumentation',
  SENT: 'Sent',
  RESEND: 'Resend',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  STAND_BY: 'StandBy',
});

export const OPTION_TYPES = Object.freeze({
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  DATE: 'date',
});

export const ERROR_CODES = Object.freeze({
  FIREBASE_PERMISSION: 'permission-denied',
});

export const PHONE_COUNTRY_CODE_BY_LOCALE = Object.freeze({
  'pt-BR': '+55',
  'es-MX': '+52',
});

export function getDefaultPhoneCountryCode(locale) {
  return (
    PHONE_COUNTRY_CODE_BY_LOCALE[locale] ||
    PHONE_COUNTRY_CODE_BY_LOCALE['pt-BR']
  );
}

export const OFFERS_TAB = 0;
export const DOCUMENTATIONS_TAB = 1;
export const CONTRACTS_TAB = 2;

export const TAB_LABELS = Object.freeze({
  [OFFERS_TAB]: 'offers',
  [DOCUMENTATIONS_TAB]: 'documentations',
  [CONTRACTS_TAB]: 'contracts',
});

export const TAB_LABELS_TO_TRACKING_STATUS = Object.freeze({
  [TAB_LABELS[OFFERS_TAB]]: 'negotiation',
  [TAB_LABELS[DOCUMENTATIONS_TAB]]: 'documentation',
  [TAB_LABELS[CONTRACTS_TAB]]: 'contract',
});

export const KEY_TRACKING_STATUS = Object.freeze({
  DELIVERED: 'DELIVERED',
  NOT_DELIVERED: 'NOT_DELIVERED',
  RETURNED: 'RETURNED',
});

export const WHEN_AGENT_GOT_KEY = Object.freeze({
  BEFORE_FIRST_VISIT: 'BEFORE_FIRST_VISIT',
  ON_FIRST_VISIT: 'ON_FIRST_VISIT',
  AFTER_FIRST_VISIT: 'AFTER_FIRST_VISIT',
});

export const HOUSE_TYPE_MESSAGE_MAP = Object.freeze({
  [HOUSE_TYPES.HOUSE]: {
    message: appMessages.houseType,
  },
  [HOUSE_TYPES.APARTMENT]: {
    message: appMessages.apartmentType,
  },
  [HOUSE_TYPES.CONDO_HOUSE]: {
    message: appMessages.condoHouseType,
  },
  [HOUSE_TYPES.STUDIO]: {
    message: appMessages.studioType,
  },
});

export const CONDOMINIUM_TYPES_MESSAGE_MAP = Object.freeze({
  [CONDOMINIUM_TYPES.NORMAL]: {
    message: appMessages.numberValue,
    name: CONDOMINIUM_TYPES.NORMAL,
  },
  [CONDOMINIUM_TYPES.NOT_INFORMED]: {
    message: appMessages.notInformed,
    name: CONDOMINIUM_TYPES.NOT_INFORMED,
  },
  [CONDOMINIUM_TYPES.NOT_EXIST]: {
    message: appMessages.noMoney,
    name: CONDOMINIUM_TYPES.NOT_EXIST,
  },
  [CONDOMINIUM_TYPES.INCLUDED]: {
    message: appMessages.included,
    name: CONDOMINIUM_TYPES.INCLUDED,
  },
});

export const HOUSE_CATEGORY = Object.freeze({
  STANDARD: 'STANDARD',
  VERIFIED: 'SPECIAL',
});
