import messages from './messages';

export const RENT_PROGRESS_STATUS_PAGE_VIEWED =
  'agentsPwa/RentProgress/RENT_PROGRESS_STATUS_PAGE_VIEWED';
export const RENT_PROGRESS_STATUS_FILTER_CLICKED =
  'agentsPwa/RentProgress/RENT_PROGRESS_STATUS_FILTER_CLICKED';
export const RENT_PROGRESS_STATUS_FILTER_SUBMITTED =
  'agentsPwa/RentProgress/RENT_PROGRESS_STATUS_FILTER_SUBMITTED';
export const RENT_PROGRESS_STATUS_CARD_OPENED =
  'agentsPwa/RentProgress/RENT_PROGRESS_STATUS_CARD_OPENED';
export const RENT_PROGRESS_CHAT_WITH_OWNER_CLICKED_ANALYTICS =
  'agentsPwa/RentProgress/RENT_PROGRESS_CHAT_WITH_OWNER_CLICKED_ANALYTICS';

export const RENT_PROGRESS_STATUS_CONTRACT = 'contract';
export const RENT_PROGRESS_STATUS_DOCUMENTATION = 'documentation';
export const RENT_PROGRESS_STATUS_NEGOTIATION = 'negotiation';

export const RENT_PROGRESS_TABS = [
  messages.negotiation,
  messages.documentation,
  messages.contracts,
];

export const INITIAL_PAGE = 0;
export const PAGE_SIZE = 10;
