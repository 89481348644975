export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const HOUSE_ID_PREFIX = 892700000;
export const EPOCH_PATH_CONSTANT_FACTOR = 1000;
export const POSSIBLE_PHONE_REGEX = /^[+]?\d+$/;
export const VALID_EMAIL_REGEX = /\S+@\S+\.\S+/;
export const VALID_HOUSE_ID_REGEX = '^[0-9]+$';
export const RENT_BUSINESS_CONTEXT = 'RENT';
export const SALE_BUSINESS_CONTEXT = 'SALE';
export const HOUSE_ID_LENGTH = 9;
