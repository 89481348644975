export const RENT_FLOW_LABEL = Object.freeze({
  CONTRACT_CANCELLED: 'ContractCancelled',
  CONTRACT_SIGNED: 'ContractSigned',
  DOCUMENTATIONS_IN_ANALYSIS: 'DocumentationsInAnalysis',
  DOCUMENTATIONS_REFUSED: 'DocumentationsRefused',
  HOUSE_INACTIVE: 'HouseInactive',
  HOUSE_UNPUBLISHED: 'HouseUnpublished',
  OWNER_DOCUMENTATION_IN_ANALYSIS: 'OwnerDocumentationInAnalysis',
  OWNER_DOCUMENTATION_IN_ANALYSIS_CHIP: 'OwnerDocumentationInAnalysisChip',
  OWNER_REJECTED_OFFER: 'OwnerRejectedOffer',
  RENT_FLOW_CANCELLED: 'RentFlowCancelled',
  STALLED_REJECTION: 'StalledRejection',
  TENANT_DIDNT_SENT_OFFER: 'TenantDidntSentOffer',
  TENANT_DOCUMENTATION_IN_ANALYSIS: 'TenantDocumentationInAnalysis',
  TENANT_DOCUMENTATION_IN_ANALYSIS_CHIP: 'TenantDocumentationInAnalysisChip',
  TENANT_REJECTED: 'TenantRejected',
  TENANT_REJECTED_OFFER: 'TenantRejectedOffer',
  WAITING_CONTRACT: 'WaitingContract',
  WAITING_CONTRACT_SIGNATURES: 'WaitingContractSignatures',
  WAITING_DOCUMENTATIONS: 'WaitingDocumentations',
  WAITING_FOR_TENANT_TO_DECIDE: 'WaitingForTenantToDecide',
  WAITING_OWNER_DOCUMENTATION: 'WaitingOwnerDocumentation',
  WAITING_OWNER_RESEND_DOCUMENTATION: 'WaitingOwnerResendDocumentation',
  WAITING_OWNER_RESPOND_COUNTER_OFFER: 'WaitingOwnerRespondCounterOffer',
  WAITING_OWNER_RESPONSE: 'WaitingOwnerResponse',
  WAITING_OWNER_SIGN_CONTRACT: 'WaitingOwnerSignContract',
  WAITING_RESEND_DOCUMENTATIONS: 'WaitingResendDocumentations',
  WAITING_SIGNATURES_VALIDATION: 'WaitingSignaturesValidation',
  WAITING_TENANT_DOCUMENTATION: 'WaitingTenantDocumentation',
  WAITING_TENANT_RESEND_DOCUMENTATION: 'WaitingTenantResendDocumentation',
  WAITING_TENANT_RESPOND_COUNTER_OFFER: 'WaitingTenantRespondCounterOffer',
  WAITING_TENANT_SIGN_CONTRACT: 'WaitingTenantSignContract',
});

/**
 * Enum of visit labels
 * @type {Readonly<{*}>}
 */
export const VISIT_LABEL = Object.freeze({
  PROPOSAL_NOT_SENT: 'ProposalNotSent',
  PROPOSAL_SENT: 'ProposalSent',
  ENTRANCE_PROBLEMS: 'EntranceProblems',
});
