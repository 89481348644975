import { UNAUTHORIZED } from 'http-status-codes';

import { captureMessage } from '@quintoandar/fwp-error-monitoring';
import { logoutClearListCookies, LOGOUT_ORIGIN } from 'utils/logout';
import { redirectToLoginPage } from '../utils/redirect';

const LOGOUT = 'LOGOUT';

const logout = () => {
  logoutClearListCookies(LOGOUT_ORIGIN.UNAUTHORIZED_REDIRECTOR);
  return { type: LOGOUT };
};

export const unauthorizedRedirector = (store) => (next) => (action) => {
  if (action?.payload?.response?.status !== UNAUTHORIZED) {
    return next(action);
  }

  captureMessage('unauthorizedRedirector', {
    extra: {
      action,
      store,
    },
  });

  store.dispatch(logout());
  store.dispatch(redirectToLoginPage(action.payload.redirectBack));

  return undefined;
};

export default unauthorizedRedirector;
