import { ELEMENTS_PER_PAGE } from 'config';
import { fromJS } from 'immutable';
import { parseSnapshotToArray } from 'utils';
import { parseRentFlowLabel, parseVisitLabel } from 'utils/parsers/parseLabel';
import parseOwnerStatus from 'utils/parsers/parseOwnerStatus';
import parseTenantStatus from 'utils/parsers/parseTenantStatus';

export const parseRentFlowData = (data) =>
  data.map((doc) => {
    const docCopy = doc;
    docCopy.labelData = {};
    docCopy.summaries = [];

    if (docCopy.label) {
      docCopy.labelData = parseRentFlowLabel(docCopy);
    }

    if (docCopy.tenantSummary) {
      docCopy.summaries[0] = parseTenantStatus(docCopy);
    }

    if (docCopy.ownerSummary) {
      docCopy.summaries[1] = parseOwnerStatus(docCopy);
    }

    return docCopy;
  });

export const parseVisitData = (data) =>
  data.map((doc) => ({
    ...doc,
    labelData: doc.label ? parseVisitLabel(doc) : {},
  }));

export const handleFetchCollectionSuccess = ({ state, payload, tab }) => {
  const collections = parseSnapshotToArray(payload.data);
  const collectionsCount = collections.length;
  const loadMore = collectionsCount === ELEMENTS_PER_PAGE + 1;
  const lastItem =
    payload.data.docs[loadMore ? collectionsCount - 2 : collectionsCount - 1];

  // Update each proposal with data used to render the card (label background color, label message, summaries, etc)
  let parsedCollections;
  if (tab === 'visits') {
    parsedCollections = parseVisitData(collections);
  } else {
    parsedCollections = parseRentFlowData(
      collections?.map((doc) => ({
        ...doc,
        signedAt: doc?.signedAt?.toDate(),
        contractChangedAt: doc?.contractChangedAt?.toDate(),
        offerDeadline: doc?.offerDeadline?.toDate(),
      })),
    );
  }

  const newCollection = [
    ...state.toJS().data,
    ...(loadMore
      ? parsedCollections.slice(0, collectionsCount - 1)
      : parsedCollections),
  ];

  return state
    .set('data', fromJS(newCollection))
    .set('lastItem', lastItem)
    .set('loadMore', loadMore)
    .set('isLoaded', true)
    .set('isLoading', false)
    .set('shouldFetchData', false);
};

export const handleFetchCollectionRequest = (state, payload, initialState) => {
  if (!payload.lastItem) {
    return state
      .merge(initialState)
      .set('isLoaded', false)
      .set('isLoading', true);
  }

  return state.set('isLoaded', false).set('isLoading', true);
};
