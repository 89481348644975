/**
 * Format the received house to be displayed in cards
 *
 * @param {object} house house with address fields
 * @param withComplement
 * @returns {string} formatted house address with number and complement
 */
export const formatAddress = (house, withComplement = true) => {
  const location = house.address || house.street || '';
  const number = house.number ? `, ${house.number}` : '';
  const complement =
    withComplement && house.complement ? ` - ${house.complement}` : '';

  return `${location}${number}${complement}`;
};

export const getFormattedAddress = (house) => {
  if (house && house.address) {
    if (house.address instanceof Object) {
      return formatAddress(house.address);
    }
    return formatAddress(house);
  }
  return null;
};

export const formatFullAddress = ({
  street,
  number,
  neighborhood,
  city,
  state,
  postalCode,
}) => {
  const validStreet = street || '';
  const validNumber = number ? `, ${number}` : '';
  const validNeighborhood = neighborhood ? ` - ${neighborhood}` : '';
  const validCity = city ? `, ${city}` : '';
  const validState = state ? ` - ${state}` : '';
  const validPostalCode = postalCode ? `, ${postalCode}` : '';
  return `${validStreet}${validNumber}${validNeighborhood}${validCity}${validState}${validPostalCode}`;
};

const placeholder = ' - ';

/**
 * Format the received address to be displayed in cards
 *
 * @param {object} address address with fields
 * @returns {string} formatted address with number and neighborhood
 */
export const formatAddressWithNeighborhood = ({
  address,
  number,
  neighborhood,
}) => {
  let result = address;

  if (number) {
    result = `${result}, ${number}`;
  }

  if (neighborhood) {
    result = `${result}${placeholder}${neighborhood}`;
  }

  return result;
};

/**
 * Format the received address to be used on Google Maps deep link
 *
 * @param {string} address
 * @param {string} [number]
 * @param {string} [neighborhood]
 * @param {string} [city]
 * @param {string} [stateName]
 * @returns {string} formatted address with number, neighborhood, city and state name
 */
export const formatAddressWithCityAndState = ({
  address,
  number,
  neighborhood,
  city,
  stateName,
}) => {
  let result = formatAddressWithNeighborhood({ address, number, neighborhood });

  if (city) {
    result = `${result}, ${city}`;
  }

  if (stateName) {
    result = `${result}${placeholder}${stateName}`;
  }

  return result;
};

export const formatNameBase = (name, secondNameFormat) => {
  if (!name) {
    return placeholder;
  }

  const [firstName, secondName] = name.split(' ');

  return secondName ? `${firstName} ${secondNameFormat(secondName)}` : name;
};

/**
 * Format the received name to pattern 'Name SecondName' => 'Name S.'
 * @param {string} name - One full name
 */
export const formatName = (name) =>
  formatNameBase(name, (secondName) => `${secondName.charAt(0)}.`);

/**
 * Format the received name to pattern 'Name SecondName Bla' => 'Name SecondName'
 * @param {string} name - One full name
 */
export const formatFirstAndSecondName = (name) =>
  formatNameBase(name, (secondName) => secondName);

/**
 * Format the received name to pattern 'FirstName MiddleName1 MiddleName2 [...] LastName' => 'FirstName LastName'
 * @param {string} name - One full name
 */
export const formatFirstAndLastNames = (name) => {
  const nameType = typeof name;
  if (!name || nameType !== 'string') {
    return '';
  }

  const names = name.trim().split(/\s/);
  const firstName = names[0];
  const lastName = names.length > 1 ? names[names.length - 1] : null;

  return lastName ? `${firstName} ${lastName}` : firstName;
};

/**
 * Formats the received name keeping only the first word
 * @param {string} name One full name
 * @returns {string} The first word of the supplied name
 */
export const formatFirstName = (name) => {
  if (!name) {
    return '';
  }

  return name.trim().split(' ', 1)[0];
};

/**
 * Concatenates the received array of elements, filtering what is not a string, into one string
 * @param {array} elementsArray Array of elements to be concatenated
 * @param {string} separator String to be joined between elements
 * @returns {string} Concatenated string
 */
export const filterAndJoinIntoString = ({ elementsArray, separator = ' / ' }) =>
  elementsArray
    .filter((element) => typeof element === 'string')
    .join(separator);

/**
 * Formats the received house type and complement in a pattern used in several places
 *
 * @param {string} type house type e.g. Apartamento
 * @param {string} complement house complement e.g. Apto 71
 * @returns {string} Concatenated type and complement separated by a specific character
 */
export const formatHouseAddressTypeAndComplement = ({ type, complement }) => {
  const complementMessage = complement ? ` • ${complement}` : '';

  return `${type}${complementMessage}`;
};

/**
 * Capitalizes the first letter of the received string
 *
 * @param {string} string string to be capitalized
 * @returns {string} capitalized string
 */
export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Shortens the name to first two initials
 *
 * @param {string} string name to be shorten
 * @returns {string} name initials
 */
export const getNameFirstAndLastInitials = (name) => {
  if (!name) return placeholder;

  const fullName = name.split(' ');
  if (fullName.length === 1) return fullName.shift().charAt(0).toUpperCase();

  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

/**
 * Normalizes the name in lowercase and without accents
 *
 * @param {string} string name to be normalized
 * @returns {string} normalized name
 */
export const nameNormalizer = (name) =>
  name
    ? name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : '';
