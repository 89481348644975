import { USER_TYPES } from 'config';

import { FormattedDate, FormattedTime } from 'react-intl';
import contractMessages from 'containers/RentProgress/Contracts/messages';
import proposalMessages from 'containers/RentProgress/Offers/messages';
import { NEGOTIATION_STATUS } from 'enums/negotiationStatus';
import { TENANT_SUMMARY } from 'enums/userStatus';
import parseRejectionReason from 'utils/parsers/parseRejectionReason';

const parseTenantStatus = (rentFlow) => {
  const offerDeadline = rentFlow?.offerDeadline;
  const rejectionReason =
    rentFlow && rentFlow.rejectionReason !== null
      ? parseRejectionReason(rentFlow.rejectionReason)
      : null;

  switch (rentFlow.tenantSummary) {
    // TODO: We may need a better check here to really determine which person stalled
    case TENANT_SUMMARY.STALLED_REJECTION:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.stalledRejectionTenantStatus,
        status: NEGOTIATION_STATUS.TERMINATED,
      };
    case TENANT_SUMMARY.TENANT_APPROVED_DOCUMENTATION:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantApprovedDocumentation,
        status: NEGOTIATION_STATUS.COMPLETED,
      };
    case TENANT_SUMMARY.TENANT_DEADLINE_DOCUMENTATION:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantDeadlineDocumentationNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case TENANT_SUMMARY.TENANT_DEADLINE_RESEND_DOCUMENTATION:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantDeadlineResendDocumentationNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case TENANT_SUMMARY.TENANT_DIDNT_SENT_OFFER:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantDidntSentOfferTenantStatus,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case TENANT_SUMMARY.TENANT_DOCUMENTATION_IN_ANALYSIS:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantDocumentationInAnalysis,
        status: NEGOTIATION_STATUS.WAITING5A,
      };
    case TENANT_SUMMARY.TENANT_REJECTED:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantRejected,
        status: NEGOTIATION_STATUS.TERMINATED,
      };
    case TENANT_SUMMARY.TENANT_REJECTED_OFFER:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantRejectedOfferTenantStatus,
        status: NEGOTIATION_STATUS.TERMINATED,
        values: { rejectionReason },
      };
    case TENANT_SUMMARY.TENANT_SIGNED_CONTRACT:
      return {
        id: USER_TYPES.TENANT,
        message: contractMessages.tenantSignedContract,
        status: NEGOTIATION_STATUS.COMPLETED,
      };
    case TENANT_SUMMARY.TENANT_WITH_MULTIPLE_DOCS:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantWithMultipleDocs,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case TENANT_SUMMARY.WAITING_OWNER_RESPOND_COUNTER_OFFER:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.waitingOwnerRespondCounterOfferTenantStatus,
        status: NEGOTIATION_STATUS.COMPLETED,
      };
    case TENANT_SUMMARY.WAITING_OWNER_RESPONSE:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.waitingOwnerResponseTenantStatus,
        status: NEGOTIATION_STATUS.COMPLETED,
      };
    case TENANT_SUMMARY.WAITING_TENANT_RESPOND_COUNTER_OFFER:
      if (offerDeadline) {
        return {
          id: USER_TYPES.TENANT,
          message:
            proposalMessages.waitingTenantRespondCounterOfferTenantStatus,
          status: NEGOTIATION_STATUS.WAITING,
          values: {
            date: (
              <FormattedDate
                day="2-digit"
                month="2-digit"
                value={offerDeadline}
              />
            ),
            hour: (
              <FormattedTime
                hour="2-digit"
                minute="2-digit"
                value={offerDeadline}
              />
            ),
          },
        };
      }

      return {
        id: USER_TYPES.TENANT,
        message:
          proposalMessages.waitingTenantRespondCounterOfferTenantStatusNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case TENANT_SUMMARY.WAITING_TENANT_SIGN_CONTRACT:
      return {
        id: USER_TYPES.TENANT,
        message: contractMessages.waitingTenantSignContractTenantStatusNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case TENANT_SUMMARY.WAITING_TENANT_TO_DECIDE:
      return {
        id: USER_TYPES.TENANT,
        message: proposalMessages.tenantWithMultipleDocs,
        status: NEGOTIATION_STATUS.WAITING,
      };
    default:
      return {};
  }
};

export default parseTenantStatus;
