import { USER_TYPES } from 'config';

import { FormattedDate, FormattedTime } from 'react-intl';
import contractMessages from 'containers/RentProgress/Contracts/messages';
import proposalMessages from 'containers/RentProgress/Offers/messages';
import { NEGOTIATION_STATUS } from 'enums/negotiationStatus';
import { OWNER_SUMMARY } from 'enums/userStatus';
import parseRejectionReason from 'utils/parsers/parseRejectionReason';
import { getBrandName } from 'utils/i18n/brand';

const parseOwnerStatus = (rentFlow) => {
  const offerDeadline = rentFlow?.offerDeadline;
  const rejectionReason =
    rentFlow && rentFlow.rejectionReason !== null
      ? parseRejectionReason(rentFlow.rejectionReason)
      : null;

  switch (rentFlow.ownerSummary) {
    case OWNER_SUMMARY.HOUSE_INACTIVE:
      return {
        id: USER_TYPES.LANDLORD,
        message: {
          ...proposalMessages.houseInactiveOwnerStatus,
          values: { companyName: getBrandName() },
        },
        status: NEGOTIATION_STATUS.TERMINATED,
      };
    case OWNER_SUMMARY.HOUSE_UNPUBLISHED:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.houseUnpublishedOwnerStatus,
        status: NEGOTIATION_STATUS.TERMINATED,
      };
    case OWNER_SUMMARY.OWNER_APPROVED_DOCUMENTATION:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.ownerApprovedDocumentation,
        status: NEGOTIATION_STATUS.COMPLETED,
      };
    case OWNER_SUMMARY.OWNER_DEADLINE_DOCUMENTATION:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.ownerDeadlineDocumentationNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case OWNER_SUMMARY.OWNER_DEADLINE_RESEND_DOCUMENTATION:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.ownerDeadlineResendDocumentationNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case OWNER_SUMMARY.OWNER_DOCUMENTATION_IN_ANALYSIS:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.ownerDocumentationInAnalysis,
        status: NEGOTIATION_STATUS.WAITING5A,
      };
    case OWNER_SUMMARY.OWNER_REJECTED_OFFER:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.ownerRejectedOfferOwnerStatus,
        status: NEGOTIATION_STATUS.TERMINATED,
        values: { rejectionReason },
      };
    case OWNER_SUMMARY.OWNER_SIGNED_CONTRACT:
      return {
        id: USER_TYPES.LANDLORD,
        message: contractMessages.ownerSignedContract,
        status: NEGOTIATION_STATUS.COMPLETED,
      };
    case OWNER_SUMMARY.STALLED_REJECTION:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.stalledRejectionOwnerStatus,
        status: NEGOTIATION_STATUS.TERMINATED,
      };
    case OWNER_SUMMARY.WAITING_OWNER_RESPOND_COUNTER_OFFER:
      if (offerDeadline) {
        return {
          id: USER_TYPES.LANDLORD,
          message: proposalMessages.waitingOwnerRespondCounterOfferOwnerStatus,
          status: NEGOTIATION_STATUS.WAITING,
          values: {
            date: (
              <FormattedDate
                day="2-digit"
                month="2-digit"
                value={offerDeadline}
              />
            ),
            hour: (
              <FormattedTime
                hour="2-digit"
                minute="2-digit"
                value={offerDeadline}
              />
            ),
          },
        };
      }

      return {
        id: USER_TYPES.LANDLORD,
        message:
          proposalMessages.waitingOwnerRespondCounterOfferOwnerStatusNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case OWNER_SUMMARY.WAITING_OWNER_RESPONSE:
      if (offerDeadline) {
        return {
          id: USER_TYPES.LANDLORD,
          message: proposalMessages.waitingOwnerResponseOwnerStatus,
          status: NEGOTIATION_STATUS.WAITING,
          values: {
            date: (
              <FormattedDate
                day="2-digit"
                month="2-digit"
                value={offerDeadline}
              />
            ),
            hour: (
              <FormattedTime
                hour="2-digit"
                minute="2-digit"
                value={offerDeadline}
              />
            ),
          },
        };
      }

      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.waitingOwnerResponseOwnerStatusNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case OWNER_SUMMARY.WAITING_OWNER_SIGN_CONTRACT:
      return {
        id: USER_TYPES.LANDLORD,
        message: contractMessages.waitingOwnerSignContractOwnerStatusNoDate,
        status: NEGOTIATION_STATUS.WAITING,
      };
    case OWNER_SUMMARY.WAITING_TENANT_RESPOND_COUNTER_OFFER:
      return {
        id: USER_TYPES.LANDLORD,
        message: proposalMessages.waitingTenantRespondCounterOfferOwnerStatus,
        status: NEGOTIATION_STATUS.WAITING,
      };
    default:
      return {};
  }
};

export default parseOwnerStatus;
